import React from "react"
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import Popper from "popper.js"
import { createPopper } from '@popperjs/core';

import App from "./App"
import * as serviceWorker from "./serviceWorker"

import {store} from './store';

import "./polyfills"
import './i18n';

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const config = { pushKey: "BJdT_knunnsw5e-oYMbSjSBH2t2nxWsgum7Za5mevuriyEPxjTOTzGukPvaicalb9DoqMP2OJr46EEzS_22Cm0U"}
serviceWorker.register(config)
// serviceWorker.unregister()
