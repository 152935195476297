import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

import AdminContainer from './containers/AdminContainer'

import Loader from './shared/Loader'
import LayoutCustomBlank from './shared/layouts/LayoutCustomBlank'

const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> })

export const DefaultLayout = LayoutCustomBlank

export const titleTemplate = '%s - Econq'
export const defaultRoute = '/'

export const routes = [
  ,
  {
    path: '/admin',
    component: lazy(() => import('./containers/admin/users/AdminUserListContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/channel-management',
    component: lazy(() => import('./containers/admin/channels/ChannelManagementContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/channel-management/:id',
    component: lazy(() => import('./containers/admin/channels/ChannelManagementEditContainer')),
    layout: AdminContainer
  },
  // {
  //   path: '/admin/dashboard',
  //   component: lazy(() => import('./components/admin/dashboard/Dashboard')),
  //   layout: AdminContainer
  // },
  {
    path: '/admin/products/single-plans',
    component: lazy(() => import('./containers/admin/products/AdminSinglePlanContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/products/single-plans/:id',
    component: lazy(() => import('./containers/admin/products/AdminSingleProductEditContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/products/feature-plans',
    component: lazy(() => import('./containers/admin/products/AdminFeaturePlanContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/products/packages',
    component: lazy(() => import('./containers/admin/products/AdminPackagePlanContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/products/packages/create',
    component: lazy(() => import('./containers/admin/products/AdminPackagePlanCreateContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/products/packages/:id',
    component: lazy(() => import('./containers/admin/products/AdminPackagePlanEditContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/ui-settings-page',
    component: lazy(() => import('./components/admin/ui-settings-page/UiSettingsPage')),
    layout: AdminContainer
  },
  {
    path: '/admin/ui-settings-page',
    component: lazy(() => import('./components/admin/ui-settings-page/UiSettingsPage')),
    layout: AdminContainer
  },
  {
    path: '/admin/roles-permissions',
    component: lazy(() => import('./containers/admin/roles/AdminRoleListContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/roles-permissions/permissions/:id',
    component: lazy(() => import('./containers/admin/roles/AdminRolePermissionsEditContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/roles-permissions/platform',
    component: lazy(() => import('./components/admin/roles-permissions/PlatformAccessPermissions')),
    layout: AdminContainer
  },
  {
    path: '/admin/roles-permissions/channel',
    component: lazy(() => import('./components/admin/roles-permissions/ChannelsAccessPermissions')),
    layout: AdminContainer
  },
  {
    path: '/admin/roles-permissions/create-role',
    component: lazy(() => import('./containers/admin/roles/AdminRoleCreateContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/roles-permissions/:id',
    component: lazy(() => import('./containers/admin/roles/AdminRoleEditContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/moderation',
    component: lazy(() => import('./containers/admin/content/AdminModerationContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/customizations/message-categories',
    component: lazy(() => import('./containers/admin/categories/CategoriesContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/customizations/external-links',
    component: lazy(() => import('./containers/admin/customizations/ExternalLinksContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/customizations/navigation-content',
    component: lazy(() => import('./containers/admin/customizations/NavigationContentContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/customizations/template-workspaces',
    component: lazy(() => import('./containers/admin/template-workspaces/TemplateWorkspacesContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/customizations/registration-authenication',
    component: lazy(() => import('./containers/admin/custom/RegistrationAuthenicationContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/customizations/corporate-branding',
    component: lazy(() => import('./components/admin/corporate-branding/CorporateBranding')),
    layout: AdminContainer
  },
  {
    path: '/admin/customizations/privacy',
    component: lazy(() => import('./containers/admin/privacy/PrivacyContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/customizations/privacy-policy',
    component: lazy(() => import('./containers/admin/customizations/PrivacyPolicyContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/customizations/sidebar-links',
    component: lazy(() => import('./containers/admin/customizations/SidebarLinks/SidebarLinks')),
    layout: AdminContainer
  },
  {
    path: '/admin/integrations/payments',
    component: lazy(() => import('./containers/admin/integrations/payments/PaymentsContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/integrations/copytrading',
    component: lazy(() => import('./containers/admin/integrations/copytrading/CopytradingContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/integrations/copytrading/reports',
    component: lazy(() => import('./components/admin/integrations/copytrading/AdminCopytradingReports/AdminCopytradingReports')),
    layout: AdminContainer
  },
  {
    path: '/admin/users',
    component: lazy(() => import('./containers/admin/users/AdminUserListContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/users/:id',
    component: lazy(() => import('./containers/admin/users/AdminUserEditContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/extras',
    component: lazy(() => import('./containers/admin/extra/ExtraManagementContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/extras/create',
    component: lazy(() => import('./containers/admin/extra/ExtraManagementCreateContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/extras/:id',
    component: lazy(() => import('./containers/admin/extra/ExtraManagementEditContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/groups/channels',
    component: lazy(() => import('./containers/admin/groups/ChannelGroupsContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/groups/extras',
    component: lazy(() => import('./containers/admin/groups/ExtraGroupsContainer')),
    layout: AdminContainer
  },
  {
    path: '/admin/groups/links',
    component: lazy(() => import('./containers/admin/groups/LinkGroupsContainer')),
    layout: AdminContainer
  },
  {
    path: '/:section?/:widgetId?',
    component: lazy(() => import('./containers/NewDesignContainer'))
  },
  {
    path: '/initial-setup-platform',
    component: lazy(() => import('./shared/platform/PlatformSetupContainer'))
  },
  {
    path: '/home',
    component: lazy(() => import('./containers/NewDesignContainer'))
  },
  {
    path: '/privacy',
    component: lazy(() => import('./containers/privacy/PrivacyContainer'))
  },
  {
    path: '/privacy-policy',
    component: lazy(() => import('./containers/privacy-policy/PrivacyPolicyContainer'))
  },
  {
    path: '/eula',
    component: lazy(() => import('./containers/eula/EulaContainer'))
  }
]
